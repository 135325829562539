import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Box, CMSContent, Icon, Text } from '@deseretbook/react-ui'
import './MediaCard.css'
import MediaCard from './MediaCard'
import { detailsUrl } from '../../../utils/routes'
import InLibraryBadge from '../inLibraryBadge/InLibraryBadge'
import useUserSub from '../../../hooks/useUserSub'
import trimHtml from '../../../utils/trimHtml'
import AddToLibrary from '../../buttons/AddToLibrary'
import { DIGITAL_VIP_IDS } from '../../../subscriptionIds'
import AuthorLinks from '../authorLinks/AuthorLinks'
import checkMediaSubscription from '../../../utils/checkMediaSub'

/**
 * A component for displaying a media object.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function MediaCardSearchResult(props) {
  const { className, media: defaultMedia, isAudiobook, updateMedia, isPlus, ...rest } = props
  const [media, setMedia] = useState(defaultMedia)
  const { userVip, userPlus } = useUserSub()
  const vipIds = DIGITAL_VIP_IDS

  if (!media) {
    return false
  }

  // Applies flexbox styles to arrange figure and descriptions
  const mediaCardContainer = classNames('MediaCardContainer', className)
  // Applies base styling to ensure figure dimensions
  const mediaCardItem = classNames('MediaCardItem')
  const linkClasses = classNames('MediaCardLink', className)

  const limitedDescription = trimHtml(media.description, 500)

  const accessor = media && media.ebook ? 'ebook' : 'audiobook'

  const subscriptionPlanIds = media[accessor].subscriptionPlanIds || []

  const { inLibrary = false, purchased = false, subscribed = false, subscribable = false } =
    media[accessor] && media[accessor].userInfo

  const landingPage = detailsUrl(media.mediaId, media[accessor] && media[accessor].sku)
  const isFree = subscriptionPlanIds.includes('free')
  const isVip = checkMediaSubscription(media[accessor], vipIds)

  const isEligibleForLibrary =
    subscribable && (isFree || (userVip && (isVip || isPlus)) || (userPlus && isPlus))

  const showAddToLibrary = (!inLibrary || !subscribed) && isEligibleForLibrary

  /**
   * Handles updating the media object returned from the API
   *
   * @param {object} newMedia - The updated media object.
   * @param {number} id - The id of the media to match.
   * @param {string} sku - The sku of the media to match.
   */
  const handleUpdateMedia = (newMedia, id, sku) => {
    const formattedMedia = Object.assign({}, newMedia)

    // Remove ebook or audiobook from media object
    if (accessor === 'ebook') {
      formattedMedia.audiobook = null
    } else {
      formattedMedia.ebook = null
    }

    updateMedia(formattedMedia, id, sku)
    setMedia(formattedMedia)
  }

  return (
    <>
      <div className={mediaCardContainer} {...rest}>
        <MediaCard
          {...props}
          isAudiobook={accessor !== 'ebook' && isAudiobook}
          url={landingPage}
          className={mediaCardItem}
          showOptions={false}
          isPlus={false}
        />

        <Box alignItems="start" justifyContent="start" isColumn isFullwidth>
          <Box alignItems="start" isColumn justifyContent="space-between" isFullwidth>
            <Link to={landingPage} href={landingPage} className={linkClasses}>
              <Box alignItems="center" justifyContent="start" isFullwidth>
                <Text style={{ marginBottom: '0rem' }} isBold>
                  {media && media.title}
                </Text>
                {isPlus && (
                  <Icon
                    style={{
                      width: '7rem',
                      minWidth: '7rem',
                      height: '1.25rem',
                      marginLeft: '0.75rem',
                      display: 'inline-block',
                    }}
                    name="plus"
                  />
                )}
              </Box>
            </Link>
            {media && media.authors && media.authors.length > 0 && (
              <Text style={{ fontSize: '1rem', color: '#4a4a4a' }}>
                <AuthorLinks authors={media.authors} />
              </Text>
            )}
          </Box>
          <Box paddingTop={1} maxHeight={10}>
            <CMSContent
              dangerouslySetInnerHTML={{ __html: limitedDescription }}
              itsNotDangerousBecause="media.description comes directly from the Bookshelf API, and should be trustworthy"
            />
          </Box>
        </Box>
        <Box marginLeft={1.5} alignItems="center" justifyContent="center" minWidth={10} width={10}>
          {inLibrary && (
            <InLibraryBadge
              isFree={isFree}
              isPlus={userPlus}
              isVip={userVip}
              purchased={purchased}
            />
          )}
          {showAddToLibrary && (
            <AddToLibrary
              isFree={isFree}
              isPlus={isPlus && userPlus}
              isVip={(isPlus || isVip) && userVip}
              linkClasses={linkClasses}
              mediaId={media.mediaId}
              updateMedia={handleUpdateMedia}
              sku={media[accessor] && media[accessor].sku}
            />
          )}
        </Box>
      </div>
    </>
  )
}

MediaCardSearchResult.defaultProps = {
  className: '',
  media: {},
  isAudiobook: false,
  isPlus: false,
  updateMedia: () => {},
}

MediaCardSearchResult.propTypes = {
  /** Additional classes */
  className: PropTypes.string,
  /** Media object to be rendered */
  media: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    authors: PropTypes.shape,
    mediaId: PropTypes.string,
  }),
  /** If the media is an audiobook */
  isAudiobook: PropTypes.bool,
  /** If the media is a Plus title */
  isPlus: PropTypes.bool,
  updateMedia: PropTypes.func,
}

export default MediaCardSearchResult
